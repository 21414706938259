import React from 'react'
import HomeFaq from '../home/HomeFaq'
import Footer from '../../component/layout/Footer'

const Faq = () => {
  return (
    <>
      <HomeFaq />
      <Footer />
    
    </>
  )
}

export default Faq