import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAboutUs from './home/HomeAboutUs'
import HomeFaq from './home/HomeFaq'
import HomePrograms from './home/HomePrograms'
import HomeArabicCurriculum from './home/HomeArabicCurriculum'
import HomeResources from './home/HomeResources'
import HomeFamilyPortal from './home/HomeFamilyPortal'
import HomeIslam from './home/HomeIslam'
import NowEnrolling from './nowenrolling/NowEnrolling'


const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAboutUs />
      <HomeIslam />
      <HomePrograms />
      <HomeArabicCurriculum />
      <HomeResources />
      <HomeFamilyPortal />
      <HomeFaq />
      <NowEnrolling />





    </>
  )
}

export default Home