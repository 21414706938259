import React from 'react'
import SliderHomeArabicCurriculum from '../../component/slidercomponent/SliderHomeArabicCurriculum'

const HomeArabicCurriculum = () => {
    return (
        <>
            <section className="tf__categories mt_95" style={{overflow:"hidden"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__heading_area mb_15">
                                <h5> Al-Qalam Triangle Academy</h5>
                                <h2>Arabic Curriculum</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category light_blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Conversation</h3>
                                    <p>Students are given ample class time to acquire oral expression skills through dialogue and conversation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Literary</h3>
                                    <p>Teachers explore figurative expressions to enhance students’ literary appreciation of texts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category gray">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Writing</h3>
                                    <p>Students learn the Arabic alphabet and progress to writing words with correct spelling.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category orange">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Listening Comprehension</h3>
                                    <p>Students develop the ability to understand what they hear through audio and video materials.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category red">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Reading</h3>
                                    <p>Various methodological approaches, such as repetition, mock reading, and practicing aloud, help students reach a satisfying level of reading.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                            <div className="tf__single_category blue">
                                <div className="tf__single_category_icon">
                                    <i className="fal fa-book" />
                                </div>
                                <div className="tf__single_category_text">
                                    <h3>Reading Comprehension</h3>
                                    <p>Students start with reading letters, progress to forming words, and are taught word analysis processes, and scaling to understand what they read.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}

export default HomeArabicCurriculum