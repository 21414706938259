import React from 'react'
import { Link } from "react-router-dom";


const HomeAboutUs = () => {
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <section className="tf__categories mt_95">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                                <div className="tf__heading_area mb_15">
                                    <h5>About Us</h5>
                                    <h2>Mission & Vision </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                                <Link to="/about-us" style={{ color: "black" }}>
                                    <div className="tf__single_category light_blue">
                                        <div className="tf__single_category_icon">
                                            <i className="fal fa-book" />
                                        </div>
                                        <div className="tf__single_category_text">
                                            <h3>Mission</h3>
                                            <p>We aspire to nurture rising leaders by providing an inclusive, exceptional, Islamic environment that empowers children to aim for the stars.</p>
                                            {/* <p style={{marginLeft:"270px"}}><b>More</b> <i class='fas fa-angle-double-right'></i> </p> */}
                                        </div>
                                        
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-6 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                                <Link to="/about-us" style={{ color: "black" }}>
                                    <div className="tf__single_category blue">
                                        <div className="tf__single_category_icon">
                                            <i className="fal fa-book" />
                                        </div>
                                        <div className="tf__single_category_text">
                                            <h3>Vision</h3>
                                            <p>Confident and inspired young Muslims with a lifelong passion for learning.</p>
                                            <br />
                                            {/* <p style={{marginLeft:"270px"}}><b>More</b> <i class='fas fa-angle-double-right'></i> </p> */}

                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="tf__about mt_250 xs_mt_195" style={{ background: 'url(./assets/images/about_bg.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-9 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                                <div className="tf__about_text">
                                    <div className="tf__heading_area tf__heading_area_left mb_25 mt-lg-5">
                                        <h5 className='mt-lg-5'>About Us</h5>
                                        <h2>Our Preschool’s Mission encompasses.</h2>
                                    </div>
                                    <ul>
                                        <li>Spiritual Nourishment:</li>
                                        <li>Excellence in Education:</li>
                                        <li>Inclusivity and Diversity:</li>
                                        <li> Play-based Learning:</li>
                                        <li>Safe and Nurturing Environment:</li>
                                        <li>Parent and Community Engagement:</li>
                                        <li>Moral Values and Character Development:</li>
                                    </ul>
                                    <Link to="/about-us" className="common_btn" style={{ background: "#1774ba", color: "white", textAlign: "center", fontSize: "18px" }}> more</Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-9 col-md-8 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                                <div className="tf__about_img">
                                    <img src="./assets/images/img_7.jpg" alt="about" className="img-fluid w-100" style={{ borderRadius: "10px" }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            {/* <section className="tf__courses_details mt_195 xs_mt_100" style={{overflow:"hidden"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-8">
                            <div className="tf__courses_details_area">
                                <div className="tf__courses_det_text">
                                    <h2>About Us</h2>
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                                Mission</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Vision</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Our Preschool’s Mission encompasses</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                            <div className="tf__course_overview">
                                                <ul>
                                                    <li>We aspire to nurture rising leaders by providing an inclusive, exceptional, Islamic environment that empowers children to aim for the stars.</li>
                                                    <li>We aim to provide an exceptional Islamic preschool experience that fosters the spiritual, academic, and social growth of our young learners.</li>
                                                    <li>Grounded in the teachings of Islam, we strive to create a nurturing environment where children develop a profound love for Allah, embody Islamic values, and are inspired to become compassionate and responsible global citizens.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                            <div className="tf__course_overview">
                                                <ul>
                                                    <li>Confident and inspired young Muslims with a lifelong passion for learning.</li>
                                                    <li>At Al-Qalam Triangle Academy, our vision expands by becoming a leading Islamic School that nurtures young hearts and minds, preparing them to become exemplary individuals grounded in Islamic values, knowledge and character.</li>
                                                    <li> We strive to create a vibrant learning and exploratory community where children embark on a lifelong journey of faith, education, and service to humanity so they may achieve success in this life and the hereafter.</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>
                                            <div className="tf__course_overview">
                                                <h3>Spiritual Nourishment:</h3>
                                                <ul>
                                                    <li>We strive to cultivate a deep love and connection with Allah through age-appropriate Islamic teachings, stories of the Prophet (peace be upon him), and the recitation of Qur’an. We encourage children to develop a sense of gratitude, empathy, and respect for all of Allah’s creation.</li>

                                                </ul>
                                                <h3>Excellence in Education:</h3>
                                                <ul>
                                                    <li>Our educational approach integrates Islamic principles with modern teaching methodologies. We aim to provide a high-quality early childhood education that fosters cognitive, emotional, physical, and social growth. By nurturing curious minds, we lay the foundation for lifelong learning.</li>
                                                </ul>
                                                <h3>Moral Values and Character Development:</h3>
                                                <ul>
                                                    <li>Through Islamic ethics and moral teachings, we aim to develop strong character traits in our students. Honesty, kindness, compassion, and respect are values we encourage in all aspects of their lives.</li>
                                                </ul>
                                                <h3>Inclusivity and Diversity:</h3>
                                                <ul>
                                                    <li> We celebrate the diversity of our community and promote an inclusive atmosphere where children from all backgrounds feel welcomed and cherished. We teach our students to appreciate and respect different cultures and beliefs, fostering a spirit of unity and understanding.</li>
                                                </ul>
                                                <h3>Parent and Community Engagement:</h3>
                                                <ul>
                                                    <li>We recognize the crucial role of parents in their child’s development and education. We actively involve parents in their child’s learning journey, forming a strong partnership to support their growth at school and at home. We also engage with the local Islamic community, reinforcing the values of collaboration and shared responsibility.</li>
                                                </ul>
                                                <h3>Safe and Nurturing Environment:</h3>
                                                <ul>
                                                    <li>The emotional and physical well-being of our students is of paramount importance. We maintain a safe, secure, and caring environment where children feel confident to explore, create, and express themselves freely.</li>
                                                </ul>
                                                <h3>Play-based Learning:</h3>
                                                <ul>
                                                    <li>We believe that play is an integral part of early childhood education. Through play-based learning, we encourage imagination, creativity, and problem-solving skills, allowing children to discover and learn at their own pace.</li>
                                                </ul>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <section className="tf__about mt_250 xs_mt_195 mt-lg-5" style={{ background: 'url(./assets/images/about_bg.png)', overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-9 col-lg-6 wow fadeInLeft" data-wow-duration="1.5s">
                            <div className="tf__about_text">
                                <div className="tf__heading_area tf__heading_area_left mb_25 mt-lg-5">
                                    <h5 className='mt-lg-5'>About Us</h5>
                                    <h2>Our Preschool’s Mission encompasses.</h2>
                                </div>
                                <ul>
                                    <li>Spiritual Nourishment:</li>
                                    <li>Excellence in Education:</li>
                                    <li>Inclusivity and Diversity:</li>
                                    <li> Play-based Learning:</li>
                                    <li>Safe and Nurturing Environment:</li>
                                    <li>Parent and Community Engagement:</li>
                                    <li>Moral Values and Character Development:</li>
                                </ul>
                                <Link to="/about-us" className="common_btn" style={{ background: "#1774ba", color: "white", textAlign: "center", fontSize: "18px" }}> more</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-9 col-md-8 col-lg-6 wow fadeInRight" data-wow-duration="1.5s">
                            <div className="tf__about_img">
                                <img src="./assets/images/Welcome to witness.jpeg" alt="about" className="img-fluid" style={{ borderRadius: "10px" }} />
                                {/* <img src="./assets/images/Welcome to witness.jpeg" alt="about" className="img-fluid w-100" style={{ borderRadius: "10px" }} /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeAboutUs