import React from 'react'
import Hadear from './layout/Hadear'
import Footer from './layout/Footer'
import { Outlet } from 'react-router-dom';

const MainLayOut = () => {
  return (
    <>
      <Hadear />
      <Outlet />
      {/* <Footer /> */}
    </>
  );
}

export default MainLayOut